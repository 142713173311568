import { useFormik, FormikErrors } from "formik";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Layout from "../components/Layout";
import useApiData from "../hooks/useApiData";
import { post } from "../utils/Api";

import { Chips } from "primereact/chips";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import { useLocation, useNavigate } from "react-router-dom";
import AcceptButton from "../components/AcceptButton";
import ValidationError from "../components/ValidationError";
import { get } from "../utils/Api";
import { isRole } from "../utils/Common";
import { Fieldset } from "primereact/fieldset";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";

interface InventoryComponentProps {
  formik: ReturnType<typeof useFormik>;
  categoryList: any[];
  subCategoryList: any[];
  // sourcesList: any[];
  roastList: any[];
  regionList: any[];
  barcodeStrings: string[];
  setBarcodeStrings: React.Dispatch<React.SetStateAction<string[]>>;
  selectedFile: string;
  setSelectedFile: React.Dispatch<React.SetStateAction<string>>;
  selectedDetailFile: string;
  setSelectedDetailFile: React.Dispatch<React.SetStateAction<string>>;
  productLoading: boolean;
}

const InventoryComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location.state.type;

  const [selectedFile, setSelectedFile] = useState("");
  const [selectedDetailFile, setSelectedDetailFile] = useState("");
  const [freeGiftProductsLists, setFreeGiftProductsLists] = useState<any[]>();
  const [productLoading, setProductLoading] = useState(false);

  // const sources = useApiData("/admin/get_sources");
  // const sourcesData = sources.data as any;
  // const sourcesList = sourcesData?.map((item: any) => {
  //   return { label: item?.name, value: item?.id };
  // });
  const category = useApiData("/admin/get_categories");
  const categoryData = category.data as any;
  const categoryList = categoryData?.data.map((item: any) => {
    return { label: item?.name, value: item?.id };
  });

  const FILE_SIZE = 1024 * 1024 * 30; // 30MB
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  // Start: Inventory
  console.log("test", location.state.formikValueInventory);
  const formikValueInventory = location.state
    ? location.state.formikValueInventory
    : null;
  useEffect(() => {
    if (formikValueInventory && formikValueInventory.image_url) {
      setSelectedFile(formikValueInventory.image_url);
    }
    if (formikValueInventory && formikValueInventory.detail_image_url) {
      setSelectedDetailFile(formikValueInventory.detail_image_url);
    }
  }, [formikValueInventory]);

  useEffect(() => {
    getProductList();
  }, []);

  const [barcodeStrings, setBarcodeStrings] = useState(
    Array.isArray(formikValueInventory?.barcodes)
      ? formikValueInventory?.barcodes?.map((barcode: any) => barcode.barcode)
      : [],
  );

  const initialValuesInventory = {
    id: formikValueInventory?.id || 0,
    image_url: formikValueInventory?.image_url || "",
    detail_image_url: formikValueInventory?.detail_image_url || "",
    sku: formikValueInventory?.sku || "",
    name: formikValueInventory?.name || "",
    category_id: formikValueInventory?.category_id || "",
    sub_category_id: formikValueInventory?.sub_category_id || "",
    // api_key_id: formikValueInventory?.api_key_id || "",
    quantity: formikValueInventory?.quantity || "",
    cost_price: formikValueInventory?.cost_price || 0,
    price: formikValueInventory?.price || 0,
    free_gift: formikValueInventory?.free_gift === 1 ? true : false,
    subscription: formikValueInventory?.subscription === 1 ? true : false,
    status: formikValueInventory?.status === 1 ? true : true,
    barcodes: formikValueInventory?.barcodes || [],
    roast_id: formikValueInventory?.roast_id || "",
    region_id: formikValueInventory?.region_id || "",
    body: formikValueInventory?.body || "",
    acidity: formikValueInventory?.acidity || "",
    sweet: formikValueInventory?.sweet || "",
    description: formikValueInventory?.description || "",
    flavour: formikValueInventory?.flavour || "",
    weight: formikValueInventory?.weight || "",
    variety: formikValueInventory?.variety || "",
    water_temp: formikValueInventory?.water_temp || "",
    altitude: formikValueInventory?.altitude || "",
    processing: formikValueInventory?.processing || "",
    free_gift_id: formikValueInventory?.free_gift_id || "",
    free_gift_quantity: formikValueInventory?.free_gift_quantity || "",
  };

  const formik_inventory = useFormik({
    initialValues: initialValuesInventory,
    validationSchema: Yup.object({
      sku: Yup.string().required("SKU is required"),
      name: Yup.string().required("Inventory name is required"),
      category_id: Yup.number().required("Category is required"),
      // api_key_id: Yup.number().required("Source is required"),
      sub_category_id: Yup.number().required("Sub. Category is required"),
      quantity: Yup.string().when("id", (id, schema) => {
        if (+id === 0)
          return schema
            .matches(/^\d*$/, "Stock is not valid")
            .required("Stock is required");
        return schema;
      }),
      cost_price: Yup.string().when((values, schema) => {
        if (isRole(["Admin"]))
          return schema
            .matches(/^[0-9]*(\.[0-9]{0,2})?$/, "Cost Price is not valid")
            .required("Cost Price is required");
        return schema;
      }),
      price: Yup.string().when((values, schema) => {
        if (isRole(["Admin"]))
          return schema
            .matches(/^[0-9]*(\.[0-9]{0,2})?$/, "Selling Price is not valid")
            .required("Selling Price is required");
        return schema;
      }),
      free_gift_quantity: Yup.number().when(
        "free_gift_id",
        (free_gift_id: any, schema) => {
          if (+free_gift_id) {
            return free_gift_id > 0
              ? schema
                  .typeError("Free Gift Quantity must be a number")
                  .positive("Free Gift Quantity must be greater than 0")
                  .required("Free Gift Quantity is required")
              : schema;
          }
          return schema;
        },
      ),
      roast_id: Yup.number().when("category_id", (category_id: any, schema) => {
        const categoryIdAsNumber = parseInt(category_id, 10);
        return categoryIdAsNumber === 2
          ? schema.required("Roast is required")
          : schema.nullable().notRequired();
      }),
      region_id: Yup.number().when(
        "category_id",
        (category_id: any, schema) => {
          const categoryIdAsNumber = parseInt(category_id, 10);
          return categoryIdAsNumber === 2
            ? schema.required("Region is required")
            : schema.nullable().notRequired();
        },
      ),
      body: Yup.string().when("category_id", (category_id: any, schema) => {
        const categoryIdAsNumber = parseInt(category_id, 10);
        return categoryIdAsNumber === 2
          ? schema.required("Body is required")
          : schema.nullable().notRequired();
      }),
      acidity: Yup.string().when("category_id", (category_id: any, schema) => {
        const categoryIdAsNumber = parseInt(category_id, 10);
        return categoryIdAsNumber === 2
          ? schema.required("Acidity is required")
          : schema.nullable().notRequired();
      }),
      sweet: Yup.string().when("category_id", (category_id: any, schema) => {
        const categoryIdAsNumber = parseInt(category_id, 10);
        return categoryIdAsNumber === 2
          ? schema.required("Sweetness is required")
          : schema.nullable().notRequired();
      }),
      description: Yup.string().when(
        "category_id",
        (category_id: any, schema) => {
          const categoryIdAsNumber = parseInt(category_id, 10);
          return categoryIdAsNumber === 2
            ? schema.required("Description is required")
            : schema.nullable().notRequired();
        },
      ),
      flavour: Yup.string().when("category_id", (category_id: any, schema) => {
        const categoryIdAsNumber = parseInt(category_id, 10);
        return categoryIdAsNumber === 2
          ? schema.required("Flavour is required")
          : schema.nullable().notRequired();
      }),
      weight: Yup.string().when("category_id", (category_id: any, schema) => {
        const categoryIdAsNumber = parseInt(category_id, 10);
        return categoryIdAsNumber === 2
          ? schema.required("Weight is required")
          : schema.nullable().notRequired();
      }),
      variety: Yup.string().when("category_id", (category_id: any, schema) => {
        const categoryIdAsNumber = parseInt(category_id, 10);
        return categoryIdAsNumber === 2
          ? schema.required("Variety is required")
          : schema.nullable().notRequired();
      }),
      water_temp: Yup.string().when(
        "category_id",
        (category_id: any, schema) => {
          const categoryIdAsNumber = parseInt(category_id, 10);
          return categoryIdAsNumber === 2
            ? schema.required("Water temperature is required")
            : schema.nullable().notRequired();
        },
      ),
      altitude: Yup.string().when("category_id", (category_id: any, schema) => {
        const categoryIdAsNumber = parseInt(category_id, 10);
        return categoryIdAsNumber === 2
          ? schema.required("Altitude is required")
          : schema.nullable().notRequired();
      }),
      processing: Yup.string().when(
        "category_id",
        (category_id: any, schema) => {
          const categoryIdAsNumber = parseInt(category_id, 10);
          return categoryIdAsNumber === 2
            ? schema.required("Processing is required")
            : schema.nullable().notRequired();
        },
      ),
    }),
    onSubmit: async (values) => {
      const {
        id,
        image_url,
        detail_image_url,
        category_id,
        name,
        quantity,
        cost_price,
        price,
        sku,
        status,
        sub_category_id,
        // api_key_id,
        free_gift,
        free_gift_id,
        free_gift_quantity,
        subscription,
        roast_id,
        region_id,
        body,
        acidity,
        sweet,
        description,
        flavour,
        weight,
        variety,
        water_temp,
        altitude,
        processing,
        barcodes,
      } = values;
      const file: any = image_url;
      const detail_file: any = detail_image_url;
      try {
        setProductLoading(true);
        const formData = new FormData();
        if (id > 0) {
          formData.append("id", id as unknown as string);
        }
        // if (typeof file === "object" && file !== null) {
        formData.append("image_url", file);
        // }
        // if (typeof detail_file === "object" && detail_file !== null) {
        formData.append("detail_image_url", detail_file);
        // }
        formData.append("category_id", category_id as unknown as string);
        formData.append("name", name);
        formData.append("cost_price", cost_price as unknown as string);
        formData.append("price", price as unknown as string);
        formData.append("sku", sku);
        if (id === 0) {
          formData.append("quantity", quantity);
        }
        formData.append("status", status ? "1" : "0");
        formData.append(
          "sub_category_id",
          sub_category_id as unknown as string,
        );
        // formData.append("api_key_id", api_key_id as unknown as string);
        formData.append("subscription", subscription ? "1" : "0");
        formData.append("free_gift", free_gift ? "1" : "0");
        // Conditionally append additional fields if category_id is 2
        if (category_id === 2) {
          formData.append("roast_id", roast_id as unknown as string);
          formData.append("region_id", region_id as unknown as string);
          formData.append("body", body);
          formData.append("acidity", acidity);
          formData.append("sweet", sweet);
          formData.append("description", description);
          formData.append("flavour", flavour);
          formData.append("weight", weight);
          formData.append("variety", variety);
          formData.append("water_temp", water_temp);
          formData.append("altitude", altitude);
          formData.append("processing", processing);
        }
        if (free_gift_id) {
          formData.append("free_gift_id", free_gift_id);
          formData.append("free_gift_quantity", free_gift_quantity);
        }
        barcodes.forEach((barcode: any, index: any) => {
          formData.append(`barcodes[${index}]`, barcode);
        });
        const response = await post(
          "/admin/save_product_information",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );
        setProductLoading(false);
        if (response?.success) {
          toast.success(response?.message);
          setSelectedFile("");
          setSelectedDetailFile("");
          formik_inventory.resetForm();
          navigate("/inventory");
        }
      } catch (error: any) {
        setProductLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  // clear any values if category_id != 2 (Beans)
  useEffect(() => {
    if (formik_inventory.values.category_id !== 2) {
      formik_inventory.setFieldValue("roast_id", "");
      formik_inventory.setFieldValue("region_id", "");
      formik_inventory.setFieldValue("body", "");
      formik_inventory.setFieldValue("acidity", "");
      formik_inventory.setFieldValue("sweet", "");

      formik_inventory.setFieldValue("description", "");
      formik_inventory.setFieldValue("flavour", "");
      formik_inventory.setFieldValue("weight", "");
      formik_inventory.setFieldValue("variety", "");
      formik_inventory.setFieldValue("water_temp", "");
      formik_inventory.setFieldValue("altitude", "");
      formik_inventory.setFieldValue("processing", "");
    }
  }, []);

  const getProductList = useCallback(async () => {
    try {
      const response = await get(`/admin/get_all_products`);
      if (response?.data && response?.success) {
        const product = response.data.map((item: any) => ({
          label: item.name,
          value: item.id,
          image_url: item.image_url,
          sub_category_id: item.sub_category_id,
        }));
        setFreeGiftProductsLists(product);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  }, []);

  const subCategory = useApiData(
    `/admin/get_category_by_id/${formik_inventory.values.category_id || null}`,
  );
  const subCategoryData = subCategory.data as any;
  const subCategoryList = subCategoryData?.sub_category?.map((item: any) => {
    return { label: item?.name, value: item?.id };
  });

  const roastDetail = useApiData(`/admin/get_roasts`);
  const roastData = roastDetail.data as any;
  const roastList = roastData?.map((item: any) => {
    return { label: item?.name, value: item?.id };
  });

  const regionDetail = useApiData(`/admin/get_regions`);
  const regionData = regionDetail.data as any;
  const regionList = regionData?.map((item: any) => {
    return { label: item?.name, value: item?.id };
  });

  const onFileUpload = async (event: any, type: string) => {
    const file = event?.target?.files[0];
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = function () {
        const base64data = reader.result;
        if (type === "product") {
          setSelectedFile(base64data as string);
        } else if (type === "detail") {
          setSelectedDetailFile(base64data as string);
        }
      };
    }
  };

  // End: Inventory

  return (
    <div className="card-cfg !shadow-none">
      <form onSubmit={formik_inventory.handleSubmit}>
        <div className="flex md:justify-start md:flex-row flex-col gap-4">
          <div className="bg-[#f7f3f3] text-center p-[10px] rounded-[7px]">
            <div
              className={`w-[128px] h-[128px] border border-solid rounded-[6px] bg-[#FFFFFF] m-auto flex justify-center items-center relative ${
                formik_inventory.touched.image_url &&
                formik_inventory.errors.image_url
                  ? "border-[#f44336]"
                  : "border-[#ACACAC]"
              }`}
            >
              {selectedFile || formik_inventory.values.image_url ? (
                <div className="w-full h-full relative">
                  <img
                    src={selectedFile || formik_inventory.values.image_url}
                    onError={() =>
                      setSelectedFile(`${process.env.PUBLIC_URL}/no-image.png`)
                    }
                    alt="Preview"
                    className="m-[auto] w-full h-full object-cover rounded-[6px] p-[10px]"
                  />
                  <button
                    className="absolute top-[-10px] right-[-10px] rounded-[50%] bg-gradient-to-b from-[#FFD3AB] to-[#FFF6E3]"
                    onClick={() => {
                      formik_inventory.setFieldValue("image_url", "");
                      setSelectedFile("");
                    }}
                  >
                    <img
                      src={require("../assets/images/icon-close.png")}
                      alt="Remove icon"
                      className="w-[25px]"
                    />
                  </button>
                </div>
              ) : (
                <>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="image_url"
                    name="image_url"
                    hidden
                    onChange={(e: any) => {
                      onFileUpload(e, "product");
                      formik_inventory.setFieldValue(
                        "image_url",
                        e?.target?.files[0],
                      );
                      setSelectedFile(URL.createObjectURL(e?.target?.files[0]));
                    }}
                  />
                  <label
                    htmlFor="image_url"
                    className="text-[12px] leading-[15px] text-[#4C4C4C] font-[400] w-[80px] flex justify-center items-center flex-col text-center gap-y-[7px]"
                  >
                    <img
                      src={require("../assets/images/icon-add-circle.png")}
                      alt="Add icon"
                      className="w-[22px]"
                    />
                    Add Product Image
                  </label>
                </>
              )}
            </div>
            <ValidationError
              className={
                formik_inventory.touched.image_url &&
                formik_inventory.errors.image_url
                  ? "visible"
                  : "invisible"
              }
            >
              {formik_inventory.errors.image_url}
            </ValidationError>
            <div
              className={`w-[128px] h-[128px] border border-solid rounded-[6px] bg-[#FFFFFF] m-auto flex justify-center items-center relative ${
                formik_inventory.touched.detail_image_url &&
                formik_inventory.errors.detail_image_url
                  ? "border-[#f44336]"
                  : "border-[#ACACAC]"
              }`}
            >
              {selectedDetailFile ||
              formik_inventory.values.detail_image_url ? (
                <div className="w-full h-full relative">
                  <img
                    src={
                      selectedDetailFile ||
                      formik_inventory.values.detail_image_url
                    }
                    onError={() =>
                      setSelectedDetailFile(
                        `${process.env.PUBLIC_URL}/no-image.png`,
                      )
                    }
                    alt="Preview"
                    className="m-[auto] w-full h-full object-cover rounded-[6px] p-[10px]"
                  />
                  <button
                    className="absolute top-[-10px] right-[-10px] rounded-[50%] bg-gradient-to-b from-[#FFD3AB] to-[#FFF6E3]"
                    onClick={() => {
                      formik_inventory.setFieldValue("detail_image_url", "");
                      setSelectedDetailFile("");
                    }}
                  >
                    <img
                      src={require("../assets/images/icon-close.png")}
                      alt="Remove icon"
                      className="w-[25px]"
                    />
                  </button>
                </div>
              ) : (
                <>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="detail_image_url"
                    name="detail_image_url"
                    hidden
                    onChange={(e: any) => {
                      onFileUpload(e, "detail");
                      formik_inventory.setFieldValue(
                        "detail_image_url",
                        e?.target?.files[0],
                      );
                      setSelectedDetailFile(
                        URL.createObjectURL(e?.target?.files[0]),
                      );
                    }}
                  />
                  <label
                    htmlFor="detail_image_url"
                    className="text-[12px] leading-[15px] text-[#4C4C4C] font-[400] w-[80px] flex justify-center items-center flex-col text-center gap-y-[7px]"
                  >
                    <img
                      src={require("../assets/images/icon-add-circle.png")}
                      alt="Add icon"
                      className="w-[22px]"
                    />
                    Add Product Detail Image
                  </label>
                </>
              )}
            </div>
            <ValidationError
              className={
                formik_inventory.touched.detail_image_url &&
                formik_inventory.errors.detail_image_url
                  ? "visible"
                  : "invisible"
              }
            >
              {formik_inventory.errors.detail_image_url}
            </ValidationError>
            <div className="order-8 md:order-8 xl:order-8 xl:col-start-2">
              <label
                htmlFor="subscription"
                className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block"
              >
                Subscription
              </label>
              <InputSwitch
                name="subscription"
                inputId="subscription"
                checked={formik_inventory.values.subscription}
                onChange={formik_inventory.handleChange}
                onBlur={formik_inventory.handleBlur}
              />
            </div>
            <div className="order-9 md:order-9 xl:order-9">
              <label
                htmlFor="status"
                className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block"
              >
                Status
              </label>
              <InputSwitch
                name="status"
                inputId="status"
                checked={formik_inventory.values.status}
                onChange={formik_inventory.handleChange}
                onBlur={formik_inventory.handleBlur}
              />
            </div>
            <div className="order-10 md:order-10 xl:order-10">
              <label
                htmlFor="free_gift"
                className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block"
              >
                Free Gift
              </label>
              <InputSwitch
                name="free_gift"
                inputId="free_gift"
                checked={formik_inventory.values.free_gift}
                onChange={formik_inventory.handleChange}
                onBlur={formik_inventory.handleBlur}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-3 xl:grid-cols-4 w-full gap-4 bg-[#f7f3f3] p-[10px] rounded-[7px]">
            <div className="order-1 md:order-1 xl:order-1">
              <label
                htmlFor="category_id"
                className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
              >
                Category
              </label>
              <Dropdown
                className={`w-full ${
                  formik_inventory.touched.category_id &&
                  formik_inventory.errors.category_id
                    ? "p-invalid"
                    : ""
                }`}
                options={categoryList}
                name="category_id"
                inputId="category_id"
                placeholder="Category"
                value={formik_inventory.values.category_id}
                onChange={formik_inventory.handleChange}
                onBlur={formik_inventory.handleBlur}
              />
              <ValidationError
                className={
                  formik_inventory.touched.category_id &&
                  formik_inventory.errors.category_id
                    ? "visible"
                    : "invisible"
                }
              >
                {formik_inventory.errors.category_id}
              </ValidationError>
            </div>
            <div className="order-2 md:order-2 xl:order-2">
              <label
                htmlFor="sub_category_id"
                className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
              >
                Sub. Category
              </label>
              <Dropdown
                className={`w-full ${
                  formik_inventory.touched.sub_category_id &&
                  formik_inventory.errors.sub_category_id
                    ? "p-invalid"
                    : ""
                }`}
                options={subCategoryList}
                name="sub_category_id"
                inputId="sub_category_id"
                placeholder="Sub. Category"
                value={formik_inventory.values.sub_category_id}
                onChange={formik_inventory.handleChange}
                onBlur={formik_inventory.handleBlur}
              />
              <ValidationError
                className={
                  formik_inventory.touched.sub_category_id &&
                  formik_inventory.errors.sub_category_id
                    ? "visible"
                    : "invisible"
                }
              >
                {formik_inventory.errors.sub_category_id}
              </ValidationError>
            </div>
            {/* <div className="order-2 md:order-2 xl:order-2">
              <label
                htmlFor="sub_category_id"
                className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
              >
                Sources
              </label>
              <Dropdown
                className={`w-full ${
                  formik_inventory.touched.api_key_id &&
                  formik_inventory.errors.api_key_id
                    ? "p-invalid"
                    : ""
                }`}
                options={sourcesList}
                name="api_key_id"
                inputId="api_key_id"
                placeholder="Sources"
                value={formik_inventory.values.api_key_id}
                onChange={formik_inventory.handleChange}
                onBlur={formik_inventory.handleBlur}
              />
              <ValidationError
                className={
                  formik_inventory.touched.api_key_id &&
                  formik_inventory.errors.api_key_id
                    ? "visible"
                    : "invisible"
                }
              >
                {formik_inventory.errors.api_key_id}
              </ValidationError>
            </div> */}
            <div className="order-3 md:order-3 xl:order-3">
              <label
                htmlFor="name"
                className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
              >
                Inventory name
              </label>
              <InputText
                className={`w-full ${
                  formik_inventory.touched.name && formik_inventory.errors.name
                    ? "p-invalid"
                    : ""
                }`}
                name="name"
                id="name"
                placeholder="Inventory name"
                value={formik_inventory.values.name}
                onChange={formik_inventory.handleChange}
                onBlur={formik_inventory.handleBlur}
              />
              <ValidationError
                className={
                  formik_inventory.touched.name && formik_inventory.errors.name
                    ? "visible"
                    : "invisible"
                }
              >
                {formik_inventory.errors.name}
              </ValidationError>
            </div>
            <div className="order-4 md:order-4 xl:order-4">
              <label
                htmlFor="sku"
                className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
              >
                SKU
              </label>
              <InputText
                className={`w-full ${
                  formik_inventory.touched.sku && formik_inventory.errors.sku
                    ? "p-invalid"
                    : ""
                }`}
                name="sku"
                id="sku"
                placeholder="SKU"
                value={formik_inventory.values.sku}
                onChange={formik_inventory.handleChange}
                onBlur={formik_inventory.handleBlur}
              />
              <ValidationError
                className={
                  formik_inventory.touched.sku && formik_inventory.errors.sku
                    ? "visible"
                    : "invisible"
                }
              >
                {formik_inventory.errors.sku}
              </ValidationError>
            </div>
            {formik_inventory.values.id === 0 && (
              <div className="order-5 md:order-5 xl:order-5">
                <label
                  htmlFor="quantity"
                  className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                >
                  Stock
                </label>
                <InputText
                  className={`w-full ${
                    formik_inventory.touched.quantity &&
                    formik_inventory.errors.quantity
                      ? "p-invalid"
                      : ""
                  }`}
                  name="quantity"
                  id="quantity"
                  placeholder="Stock"
                  value={formik_inventory.values.quantity}
                  onChange={formik_inventory.handleChange}
                  onBlur={formik_inventory.handleBlur}
                />
                <ValidationError
                  className={
                    formik_inventory.touched.quantity &&
                    formik_inventory.errors.quantity
                      ? "visible"
                      : "invisible"
                  }
                >
                  {formik_inventory.errors.quantity}
                </ValidationError>
              </div>
            )}
            {isRole(["Admin", "Manager"]) && (
              <>
                <div className="order-6 md:order-6 xl:order-6">
                  <label
                    htmlFor="price"
                    className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                  >
                    Selling Price
                  </label>
                  <InputNumber
                    className={`w-full ${
                      formik_inventory.touched.price &&
                      formik_inventory.errors.price
                        ? "p-invalid"
                        : ""
                    }`}
                    name="price"
                    id="price"
                    placeholder="Selling Price"
                    value={formik_inventory.values.price} // Keep it as a number
                    onValueChange={(e) =>
                      formik_inventory.setFieldValue("price", e.value)
                    }
                    onBlur={formik_inventory.handleBlur}
                    prefix="$ "
                    minFractionDigits={2}
                  />
                  <ValidationError
                    className={
                      formik_inventory.touched.price &&
                      formik_inventory.errors.price
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_inventory.errors.price}
                  </ValidationError>
                </div>
                <div className="order-7 md:order-7 xl:order-7">
                  <label
                    htmlFor="cost_price"
                    className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                  >
                    Cost Price
                  </label>
                  <InputNumber
                    className={`w-full ${
                      formik_inventory.touched.cost_price &&
                      formik_inventory.errors.cost_price
                        ? "p-invalid"
                        : ""
                    }`}
                    name="cost_price"
                    id="cost_price"
                    placeholder="Cost Price"
                    value={formik_inventory.values.cost_price} // Keep it as a number
                    onValueChange={(e) =>
                      formik_inventory.setFieldValue("cost_price", e.value)
                    }
                    onBlur={formik_inventory.handleBlur}
                    prefix="$ "
                    minFractionDigits={2}
                  />
                  <ValidationError
                    className={
                      formik_inventory.touched.cost_price &&
                      formik_inventory.errors.cost_price
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_inventory.errors.cost_price}
                  </ValidationError>
                </div>
              </>
            )}
            {formik_inventory.values.category_id === 2 && (
              <>
                <div className="order-[8] md:order-[8] xl:order-[8] md:col-span-3 xl:col-span-4">
                  <label
                    htmlFor="description"
                    className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                  >
                    Description
                  </label>
                  <InputTextarea
                    className={`w-full ${
                      formik_inventory.touched.description &&
                      formik_inventory.errors.description
                        ? "p-invalid"
                        : ""
                    }`}
                    name="description"
                    id="description"
                    placeholder="Description"
                    value={formik_inventory.values.description}
                    onChange={formik_inventory.handleChange}
                    onBlur={formik_inventory.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik_inventory.touched.description &&
                      formik_inventory.errors.description
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_inventory.errors.description}
                  </ValidationError>
                </div>
                <div className="order-[9] md:order-[9] xl:order-[9] md:col-span-1">
                  <label
                    htmlFor="roast_id"
                    className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                  >
                    Roast
                  </label>
                  <Dropdown
                    className={`w-full ${
                      formik_inventory.touched.roast_id &&
                      formik_inventory.errors.roast_id
                        ? "p-invalid"
                        : ""
                    }`}
                    options={roastList}
                    name="roast_id"
                    inputId="roast_id"
                    placeholder="Roast"
                    value={formik_inventory.values.roast_id}
                    onChange={formik_inventory.handleChange}
                    onBlur={formik_inventory.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik_inventory.touched.roast_id &&
                      formik_inventory.errors.roast_id
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_inventory.errors.roast_id}
                  </ValidationError>
                </div>
                <div className="order-[10] md:order-[10] xl:order-[10] md:col-span-1">
                  <label
                    htmlFor="region_id"
                    className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                  >
                    Region
                  </label>
                  <Dropdown
                    className={`w-full ${
                      formik_inventory.touched.region_id &&
                      formik_inventory.errors.region_id
                        ? "p-invalid"
                        : ""
                    }`}
                    options={regionList}
                    name="region_id"
                    inputId="region_id"
                    placeholder="Region"
                    value={formik_inventory.values.region_id}
                    onChange={formik_inventory.handleChange}
                    onBlur={formik_inventory.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik_inventory.touched.region_id &&
                      formik_inventory.errors.region_id
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_inventory.errors.region_id}
                  </ValidationError>
                </div>
                <div className="order-[11] md:order-[11] xl:order-[11]">
                  <label
                    htmlFor="body"
                    className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                  >
                    Body (1-5)
                  </label>
                  <InputNumber
                    className={`w-full ${
                      formik_inventory.touched.body &&
                      formik_inventory.errors.body
                        ? "p-invalid"
                        : ""
                    }`}
                    name="body"
                    id="body"
                    placeholder="Body"
                    value={formik_inventory.values.body} // Keep it as a number
                    onValueChange={(e) =>
                      formik_inventory.setFieldValue("body", e.value)
                    }
                    onBlur={formik_inventory.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik_inventory.touched.body &&
                      formik_inventory.errors.body
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_inventory.errors.body}
                  </ValidationError>
                </div>
                <div className="order-[12] md:order-[12] xl:order-[12]">
                  <label
                    htmlFor="acidity"
                    className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                  >
                    Acidity (1-5)
                  </label>
                  <InputNumber
                    className={`w-full ${
                      formik_inventory.touched.acidity &&
                      formik_inventory.errors.acidity
                        ? "p-invalid"
                        : ""
                    }`}
                    name="acidity"
                    id="acidity"
                    placeholder="Acidity"
                    value={formik_inventory.values.acidity} // Keep it as a number
                    onValueChange={(e) =>
                      formik_inventory.setFieldValue("acidity", e.value)
                    }
                    onBlur={formik_inventory.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik_inventory.touched.acidity &&
                      formik_inventory.errors.acidity
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_inventory.errors.acidity}
                  </ValidationError>
                </div>
                <div className="order-[13] md:order-[13] xl:order-[13]">
                  <label
                    htmlFor="sweet"
                    className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                  >
                    Sweet (1-5)
                  </label>
                  <InputNumber
                    className={`w-full ${
                      formik_inventory.touched.sweet &&
                      formik_inventory.errors.sweet
                        ? "p-invalid"
                        : ""
                    }`}
                    name="sweet"
                    id="sweet"
                    placeholder="Sweet"
                    value={formik_inventory.values.sweet} // Keep it as a number
                    onValueChange={(e) =>
                      formik_inventory.setFieldValue("sweet", e.value)
                    }
                    onBlur={formik_inventory.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik_inventory.touched.sweet &&
                      formik_inventory.errors.sweet
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_inventory.errors.sweet}
                  </ValidationError>
                </div>
                <div className="order-[14] md:order-[14] xl:order-[14]">
                  <label
                    htmlFor="flavour"
                    className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                  >
                    Flavour
                  </label>
                  <InputText
                    className={`w-full ${
                      formik_inventory.touched.flavour &&
                      formik_inventory.errors.flavour
                        ? "p-invalid"
                        : ""
                    }`}
                    name="flavour"
                    id="flavour"
                    placeholder="Flavour"
                    value={formik_inventory.values.flavour}
                    onChange={formik_inventory.handleChange}
                    onBlur={formik_inventory.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik_inventory.touched.flavour &&
                      formik_inventory.errors.flavour
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_inventory.errors.flavour}
                  </ValidationError>
                </div>
                <div className="order-[15] md:order-[15] xl:order-[15]">
                  <label
                    htmlFor="weight"
                    className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                  >
                    Weight
                  </label>
                  <InputText
                    className={`w-full ${
                      formik_inventory.touched.weight &&
                      formik_inventory.errors.weight
                        ? "p-invalid"
                        : ""
                    }`}
                    name="weight"
                    id="weight"
                    placeholder="Weight"
                    value={formik_inventory.values.weight}
                    onChange={formik_inventory.handleChange}
                    onBlur={formik_inventory.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik_inventory.touched.weight &&
                      formik_inventory.errors.weight
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_inventory.errors.weight}
                  </ValidationError>
                </div>
                <div className="order-[16] md:order-[16] xl:order-[16]">
                  <label
                    htmlFor="variety"
                    className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                  >
                    Variety
                  </label>
                  <InputText
                    className={`w-full ${
                      formik_inventory.touched.variety &&
                      formik_inventory.errors.variety
                        ? "p-invalid"
                        : ""
                    }`}
                    name="variety"
                    id="variety"
                    placeholder="Variety"
                    value={formik_inventory.values.variety}
                    onChange={formik_inventory.handleChange}
                    onBlur={formik_inventory.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik_inventory.touched.variety &&
                      formik_inventory.errors.variety
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_inventory.errors.variety}
                  </ValidationError>
                </div>
                <div className="order-[17] md:order-[17] xl:order-[17]">
                  <label
                    htmlFor="water_temp"
                    className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                  >
                    Water Temperature
                  </label>
                  <InputText
                    className={`w-full ${
                      formik_inventory.touched.water_temp &&
                      formik_inventory.errors.water_temp
                        ? "p-invalid"
                        : ""
                    }`}
                    name="water_temp"
                    id="water_temp"
                    placeholder="Water Temperature"
                    value={formik_inventory.values.water_temp}
                    onChange={formik_inventory.handleChange}
                    onBlur={formik_inventory.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik_inventory.touched.water_temp &&
                      formik_inventory.errors.water_temp
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_inventory.errors.water_temp}
                  </ValidationError>
                </div>
                <div className="order-[18] md:order-[18] xl:order-[18]">
                  <label
                    htmlFor="altitude"
                    className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                  >
                    Altitude
                  </label>
                  <InputText
                    className={`w-full ${
                      formik_inventory.touched.altitude &&
                      formik_inventory.errors.altitude
                        ? "p-invalid"
                        : ""
                    }`}
                    name="altitude"
                    id="altitude"
                    placeholder="Altitude"
                    value={formik_inventory.values.altitude}
                    onChange={formik_inventory.handleChange}
                    onBlur={formik_inventory.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik_inventory.touched.altitude &&
                      formik_inventory.errors.altitude
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_inventory.errors.altitude}
                  </ValidationError>
                </div>
                <div className="order-[19] md:order-[19] xl:order-[19]">
                  <label
                    htmlFor="processing"
                    className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                  >
                    Processing
                  </label>
                  <InputText
                    className={`w-full ${
                      formik_inventory.touched.processing &&
                      formik_inventory.errors.processing
                        ? "p-invalid"
                        : ""
                    }`}
                    name="processing"
                    id="processing"
                    placeholder="Processing"
                    value={formik_inventory.values.processing}
                    onChange={formik_inventory.handleChange}
                    onBlur={formik_inventory.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik_inventory.touched.processing &&
                      formik_inventory.errors.processing
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_inventory.errors.processing}
                  </ValidationError>
                </div>
              </>
            )}
            <div className="order-[20] md:order-[20] xl:order-[20] md:col-span-3 xl:col-span-4">
              <label
                htmlFor="barcodes"
                className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
              >
                Barcodes
              </label>
              <Chips
                value={barcodeStrings}
                separator=","
                id="barcodes"
                name="barcodes"
                inputId="barcodes"
                onChange={(chips) => {
                  setBarcodeStrings(chips.value);
                  formik_inventory?.setFieldValue("barcodes", chips.value);
                }}
                onBlur={formik_inventory?.handleBlur}
                placeholder={
                  barcodeStrings.length >= 1
                    ? "Press 'Enter' for each additional barcode."
                    : "Input one or more barcodes, pressing 'Enter' for each additional barcode."
                }
              />
            </div>
            <div className="order-[20] md:order-[20] xl:order-[20] md:col-span-3 xl:col-span-2">
              <label
                htmlFor={`FocItem`}
                className="text-[#292929] capitalize block mb-2"
              >
                Free Gift (Optional)
              </label>
              <Dropdown
                className={`w-full`}
                filter
                options={freeGiftProductsLists || []} // Access the correct list based on index
                name={`free_gift_id`}
                inputId={`free_gift_id`}
                placeholder="Select Free Gift"
                // value={group.product_id || group.sub_category_id}
                value={formik_inventory.values.free_gift_id}
                onChange={formik_inventory.handleChange}
                onBlur={formik_inventory.handleBlur}
                optionLabel="label"
                optionValue="value"
                itemTemplate={(option) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {option.image_url && (
                      <img
                        src={option.image_url}
                        alt={option.label}
                        style={{
                          width: "30px",
                          height: "30px",
                          marginRight: "10px",
                        }}
                      />
                    )}
                    <span>{option.label}</span>
                  </div>
                )}
              />
            </div>
            <div className="order-[20] md:order-[20] xl:order-[20]">
              {formik_inventory.values.free_gift_id && (
                <div className="order-5 md:order-5 xl:order-5">
                  <label
                    htmlFor="quantity"
                    className="text-[16px] text-[#292929] font-[500] capitalize mb-2 block pl-[10px]"
                  >
                    Free Gift Quantity
                  </label>
                  <InputText
                    className={`w-full ${
                      formik_inventory.touched.free_gift_quantity &&
                      formik_inventory.errors.free_gift_quantity
                        ? "p-invalid"
                        : ""
                    }`}
                    name="free_gift_quantity"
                    id="free_gift_quantity"
                    placeholder="Free Gift Quantity"
                    value={formik_inventory.values.free_gift_quantity}
                    onChange={formik_inventory.handleChange}
                    onBlur={formik_inventory.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik_inventory.touched.free_gift_quantity &&
                      formik_inventory.errors.free_gift_quantity
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_inventory.errors.free_gift_quantity}
                  </ValidationError>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <AcceptButton
            label={formik_inventory.values.id > 0 ? "Update" : "Save"}
            loading={productLoading}
            className="mt-[20px]"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default InventoryComponent;
